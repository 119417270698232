import { render, staticRenderFns } from "./MailchimpSubscribe.vue?vue&type=template&id=2e202d06&"
import script from "./MailchimpSubscribe.vue?vue&type=script&lang=ts&"
export * from "./MailchimpSubscribe.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BlockNewsletter: require('/tmp/build_26d884fa/src/components/block/Newsletter.vue').default})
