













import { gql } from 'graphql-tag';
import { Component, mixins } from 'nuxt-property-decorator';

import { blockableBlockColorFragment, blockColorMixin } from '@/composables/block-color';

import { MailchimpSubscribeBlockDataFragment } from '@/graphql/generated';
import { PageBlockMixin } from '@/modules/cms';

@Component({
  graphqlData: gql`
    fragment MailchimpSubscribeBlockData on MailchimpSubscribeBlock {
      id
      title
      body
      buttonText
      configuration {
        url
        listId
        userId
      }
      ...BlockColor
    }

    ${blockableBlockColorFragment}
  `,
})
export default class MailchimpSubscribeBlock extends mixins(PageBlockMixin<MailchimpSubscribeBlockDataFragment>(), blockColorMixin) {}
